import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ISO8601DateTime: any;
  bigint: any;
  jsonb: any;
  numeric: any;
  timestamp: any;
};

export type Attachment = {
  __typename?: 'Attachment';
  blob: Blob;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['String'];
  thumbnailUrl: Scalars['String'];
  url: Scalars['String'];
};

/** Attributes for query attachments for a specific model */
export type AttachmentsAttributes = {
  /** Model attribute name */
  attribute: Scalars['String'];
  /** Model instance id */
  recordId: Scalars['bigint'];
  /** Model class name */
  recordType: Scalars['String'];
};

export type Blob = {
  __typename?: 'Blob';
  byteSize: Scalars['bigint'];
  checksum: Scalars['String'];
  contentType: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  filename: Scalars['String'];
  id: Scalars['String'];
  key: Scalars['String'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Autogenerated input type of CreateAttachment */
export type CreateAttachmentInput = {
  attribute: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  relatedId: Scalars['Int'];
  relatedType: Scalars['String'];
  signedId: Scalars['String'];
};

export type CreateAttachmentOutput = {
  __typename?: 'CreateAttachmentOutput';
  attachment: Attachment;
};

/** Autogenerated input type of CreateAttachments */
export type CreateAttachmentsInput = {
  attribute: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  relatedId: Scalars['Int'];
  relatedType: Scalars['String'];
  signedIds: Array<Scalars['String']>;
};

export type CreateAttachmentsOutput = {
  __typename?: 'CreateAttachmentsOutput';
  attachments: Array<Attachment>;
};

export type CreateDirectUploadInput = {
  byte_size: Scalars['Int'];
  checksum: Scalars['String'];
  content_type: Scalars['String'];
  filename: Scalars['String'];
};

export type CreateDirectUploadOutput = {
  __typename?: 'CreateDirectUploadOutput';
  direct_upload?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type MeOutput = {
  __typename?: 'MeOutput';
  avatar?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['String']>;
  current_password_required?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type UpdateUserOutput = {
  __typename?: 'UpdateUserOutput';
  avatar?: Maybe<Scalars['jsonb']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type UpdateUserUsersPkColumnsInput = {
  id: Scalars['bigint'];
};

export type UpdateUserUsersSetInput = {
  allow_password_change?: InputMaybe<Scalars['Boolean']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']>;
  confirmation_token?: InputMaybe<Scalars['String']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  encrypted_password?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  image?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  otp_secret_key?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_token?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  unconfirmed_email?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  username?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "devices" */
export type Devices = {
  __typename?: 'devices';
  brand?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamp'];
  deleted_at?: Maybe<Scalars['timestamp']>;
  device_model_id?: Maybe<Scalars['String']>;
  device_model_name?: Maybe<Scalars['String']>;
  device_name?: Maybe<Scalars['String']>;
  device_year_class?: Maybe<Scalars['Int']>;
  exponent_push_token?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  manufacturer?: Maybe<Scalars['String']>;
  os_name?: Maybe<Scalars['String']>;
  os_version?: Maybe<Scalars['String']>;
  platform_api_level?: Maybe<Scalars['Int']>;
  total_memory?: Maybe<Scalars['numeric']>;
  updated_at: Scalars['timestamp'];
  user_id: Scalars['bigint'];
};

/** aggregated selection of "devices" */
export type Devices_Aggregate = {
  __typename?: 'devices_aggregate';
  aggregate?: Maybe<Devices_Aggregate_Fields>;
  nodes: Array<Devices>;
};

/** aggregate fields of "devices" */
export type Devices_Aggregate_Fields = {
  __typename?: 'devices_aggregate_fields';
  avg?: Maybe<Devices_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Devices_Max_Fields>;
  min?: Maybe<Devices_Min_Fields>;
  stddev?: Maybe<Devices_Stddev_Fields>;
  stddev_pop?: Maybe<Devices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Devices_Stddev_Samp_Fields>;
  sum?: Maybe<Devices_Sum_Fields>;
  var_pop?: Maybe<Devices_Var_Pop_Fields>;
  var_samp?: Maybe<Devices_Var_Samp_Fields>;
  variance?: Maybe<Devices_Variance_Fields>;
};


/** aggregate fields of "devices" */
export type Devices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Devices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Devices_Avg_Fields = {
  __typename?: 'devices_avg_fields';
  device_year_class?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  platform_api_level?: Maybe<Scalars['Float']>;
  total_memory?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "devices". All fields are combined with a logical 'AND'. */
export type Devices_Bool_Exp = {
  _and?: InputMaybe<Array<Devices_Bool_Exp>>;
  _not?: InputMaybe<Devices_Bool_Exp>;
  _or?: InputMaybe<Array<Devices_Bool_Exp>>;
  brand?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  device_model_id?: InputMaybe<String_Comparison_Exp>;
  device_model_name?: InputMaybe<String_Comparison_Exp>;
  device_name?: InputMaybe<String_Comparison_Exp>;
  device_year_class?: InputMaybe<Int_Comparison_Exp>;
  exponent_push_token?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  manufacturer?: InputMaybe<String_Comparison_Exp>;
  os_name?: InputMaybe<String_Comparison_Exp>;
  os_version?: InputMaybe<String_Comparison_Exp>;
  platform_api_level?: InputMaybe<Int_Comparison_Exp>;
  total_memory?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "devices" */
export enum Devices_Constraint {
  /** unique or primary key constraint */
  DevicesPkey = 'devices_pkey',
  /** unique or primary key constraint */
  DevicesUserExponentPushTokenConstraint = 'devices_user_exponent_push_token_constraint',
  /** unique or primary key constraint */
  IndexDevicesOnUserIdAndExponentPushToken = 'index_devices_on_user_id_and_exponent_push_token'
}

/** input type for incrementing numeric columns in table "devices" */
export type Devices_Inc_Input = {
  device_year_class?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  platform_api_level?: InputMaybe<Scalars['Int']>;
  total_memory?: InputMaybe<Scalars['numeric']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "devices" */
export type Devices_Insert_Input = {
  brand?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  device_model_id?: InputMaybe<Scalars['String']>;
  device_model_name?: InputMaybe<Scalars['String']>;
  device_name?: InputMaybe<Scalars['String']>;
  device_year_class?: InputMaybe<Scalars['Int']>;
  exponent_push_token?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  os_name?: InputMaybe<Scalars['String']>;
  os_version?: InputMaybe<Scalars['String']>;
  platform_api_level?: InputMaybe<Scalars['Int']>;
  total_memory?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Devices_Max_Fields = {
  __typename?: 'devices_max_fields';
  brand?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  device_model_id?: Maybe<Scalars['String']>;
  device_model_name?: Maybe<Scalars['String']>;
  device_name?: Maybe<Scalars['String']>;
  device_year_class?: Maybe<Scalars['Int']>;
  exponent_push_token?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  manufacturer?: Maybe<Scalars['String']>;
  os_name?: Maybe<Scalars['String']>;
  os_version?: Maybe<Scalars['String']>;
  platform_api_level?: Maybe<Scalars['Int']>;
  total_memory?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Devices_Min_Fields = {
  __typename?: 'devices_min_fields';
  brand?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  device_model_id?: Maybe<Scalars['String']>;
  device_model_name?: Maybe<Scalars['String']>;
  device_name?: Maybe<Scalars['String']>;
  device_year_class?: Maybe<Scalars['Int']>;
  exponent_push_token?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  manufacturer?: Maybe<Scalars['String']>;
  os_name?: Maybe<Scalars['String']>;
  os_version?: Maybe<Scalars['String']>;
  platform_api_level?: Maybe<Scalars['Int']>;
  total_memory?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "devices" */
export type Devices_Mutation_Response = {
  __typename?: 'devices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Devices>;
};

/** on conflict condition type for table "devices" */
export type Devices_On_Conflict = {
  constraint: Devices_Constraint;
  update_columns?: Array<Devices_Update_Column>;
  where?: InputMaybe<Devices_Bool_Exp>;
};

/** Ordering options when selecting data from "devices". */
export type Devices_Order_By = {
  brand?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  device_model_id?: InputMaybe<Order_By>;
  device_model_name?: InputMaybe<Order_By>;
  device_name?: InputMaybe<Order_By>;
  device_year_class?: InputMaybe<Order_By>;
  exponent_push_token?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manufacturer?: InputMaybe<Order_By>;
  os_name?: InputMaybe<Order_By>;
  os_version?: InputMaybe<Order_By>;
  platform_api_level?: InputMaybe<Order_By>;
  total_memory?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: devices */
export type Devices_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "devices" */
export enum Devices_Select_Column {
  /** column name */
  Brand = 'brand',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeviceModelId = 'device_model_id',
  /** column name */
  DeviceModelName = 'device_model_name',
  /** column name */
  DeviceName = 'device_name',
  /** column name */
  DeviceYearClass = 'device_year_class',
  /** column name */
  ExponentPushToken = 'exponent_push_token',
  /** column name */
  Id = 'id',
  /** column name */
  Manufacturer = 'manufacturer',
  /** column name */
  OsName = 'os_name',
  /** column name */
  OsVersion = 'os_version',
  /** column name */
  PlatformApiLevel = 'platform_api_level',
  /** column name */
  TotalMemory = 'total_memory',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "devices" */
export type Devices_Set_Input = {
  brand?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  device_model_id?: InputMaybe<Scalars['String']>;
  device_model_name?: InputMaybe<Scalars['String']>;
  device_name?: InputMaybe<Scalars['String']>;
  device_year_class?: InputMaybe<Scalars['Int']>;
  exponent_push_token?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  os_name?: InputMaybe<Scalars['String']>;
  os_version?: InputMaybe<Scalars['String']>;
  platform_api_level?: InputMaybe<Scalars['Int']>;
  total_memory?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Devices_Stddev_Fields = {
  __typename?: 'devices_stddev_fields';
  device_year_class?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  platform_api_level?: Maybe<Scalars['Float']>;
  total_memory?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Devices_Stddev_Pop_Fields = {
  __typename?: 'devices_stddev_pop_fields';
  device_year_class?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  platform_api_level?: Maybe<Scalars['Float']>;
  total_memory?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Devices_Stddev_Samp_Fields = {
  __typename?: 'devices_stddev_samp_fields';
  device_year_class?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  platform_api_level?: Maybe<Scalars['Float']>;
  total_memory?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Devices_Sum_Fields = {
  __typename?: 'devices_sum_fields';
  device_year_class?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  platform_api_level?: Maybe<Scalars['Int']>;
  total_memory?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "devices" */
export enum Devices_Update_Column {
  /** column name */
  Brand = 'brand',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeviceModelId = 'device_model_id',
  /** column name */
  DeviceModelName = 'device_model_name',
  /** column name */
  DeviceName = 'device_name',
  /** column name */
  DeviceYearClass = 'device_year_class',
  /** column name */
  ExponentPushToken = 'exponent_push_token',
  /** column name */
  Id = 'id',
  /** column name */
  Manufacturer = 'manufacturer',
  /** column name */
  OsName = 'os_name',
  /** column name */
  OsVersion = 'os_version',
  /** column name */
  PlatformApiLevel = 'platform_api_level',
  /** column name */
  TotalMemory = 'total_memory',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Devices_Var_Pop_Fields = {
  __typename?: 'devices_var_pop_fields';
  device_year_class?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  platform_api_level?: Maybe<Scalars['Float']>;
  total_memory?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Devices_Var_Samp_Fields = {
  __typename?: 'devices_var_samp_fields';
  device_year_class?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  platform_api_level?: Maybe<Scalars['Float']>;
  total_memory?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Devices_Variance_Fields = {
  __typename?: 'devices_variance_fields';
  device_year_class?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  platform_api_level?: Maybe<Scalars['Float']>;
  total_memory?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  createAttachment?: Maybe<CreateAttachmentOutput>;
  createAttachments?: Maybe<CreateAttachmentsOutput>;
  create_direct_upload?: Maybe<CreateDirectUploadOutput>;
  custom_update_users_by_pk?: Maybe<UpdateUserOutput>;
  /** delete data from the table: "devices" */
  delete_devices?: Maybe<Devices_Mutation_Response>;
  /** delete single row from the table: "devices" */
  delete_devices_by_pk?: Maybe<Devices>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** insert data into the table: "devices" */
  insert_devices?: Maybe<Devices_Mutation_Response>;
  /** insert a single row into the table: "devices" */
  insert_devices_one?: Maybe<Devices>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** update data of the table: "devices" */
  update_devices?: Maybe<Devices_Mutation_Response>;
  /** update single row of the table: "devices" */
  update_devices_by_pk?: Maybe<Devices>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
};


/** mutation root */
export type Mutation_RootCreateAttachmentArgs = {
  input: CreateAttachmentInput;
};


/** mutation root */
export type Mutation_RootCreateAttachmentsArgs = {
  input: CreateAttachmentsInput;
};


/** mutation root */
export type Mutation_RootCreate_Direct_UploadArgs = {
  object: CreateDirectUploadInput;
};


/** mutation root */
export type Mutation_RootCustom_Update_Users_By_PkArgs = {
  _set?: InputMaybe<UpdateUserUsersSetInput>;
  pk_columns: UpdateUserUsersPkColumnsInput;
};


/** mutation root */
export type Mutation_RootDelete_DevicesArgs = {
  where: Devices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Devices_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootInsert_DevicesArgs = {
  objects: Array<Devices_Insert_Input>;
  on_conflict?: InputMaybe<Devices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Devices_OneArgs = {
  object: Devices_Insert_Input;
  on_conflict?: InputMaybe<Devices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_DevicesArgs = {
  _inc?: InputMaybe<Devices_Inc_Input>;
  _set?: InputMaybe<Devices_Set_Input>;
  where: Devices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Devices_By_PkArgs = {
  _inc?: InputMaybe<Devices_Inc_Input>;
  _set?: InputMaybe<Devices_Set_Input>;
  pk_columns: Devices_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** File relation for active storage models */
  attachment?: Maybe<Attachment>;
  /** Attachments relation for active storage models */
  attachments: Array<Attachment>;
  /** fetch data from the table: "devices" */
  devices: Array<Devices>;
  /** fetch aggregated fields from the table: "devices" */
  devices_aggregate: Devices_Aggregate;
  /** fetch data from the table: "devices" using primary key columns */
  devices_by_pk?: Maybe<Devices>;
  me?: Maybe<MeOutput>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


export type Query_RootAttachmentArgs = {
  input: AttachmentsAttributes;
};


export type Query_RootAttachmentsArgs = {
  input: AttachmentsAttributes;
};


export type Query_RootDevicesArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Devices_Order_By>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};


export type Query_RootDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Devices_Order_By>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};


export type Query_RootDevices_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "devices" */
  devices: Array<Devices>;
  /** fetch aggregated fields from the table: "devices" */
  devices_aggregate: Devices_Aggregate;
  /** fetch data from the table: "devices" using primary key columns */
  devices_by_pk?: Maybe<Devices>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


export type Subscription_RootDevicesArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Devices_Order_By>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};


export type Subscription_RootDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Devices_Order_By>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};


export type Subscription_RootDevices_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['bigint'];
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  allow_password_change?: Maybe<Scalars['Boolean']>;
  avatar?: Maybe<Attachment>;
  confirmation_sent_at?: Maybe<Scalars['timestamp']>;
  confirmation_token?: Maybe<Scalars['String']>;
  confirmed_at?: Maybe<Scalars['timestamp']>;
  country_code?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamp'];
  email?: Maybe<Scalars['String']>;
  encrypted_password: Scalars['String'];
  id: Scalars['bigint'];
  is_active?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  otp_secret_key?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  remember_created_at?: Maybe<Scalars['timestamp']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']>;
  reset_password_token?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  unconfirmed_email?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
  username?: Maybe<Scalars['String']>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  avg?: Maybe<Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
  stddev?: Maybe<Users_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Sum_Fields>;
  var_pop?: Maybe<Users_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Var_Samp_Fields>;
  variance?: Maybe<Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  __typename?: 'users_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  allow_password_change?: InputMaybe<Boolean_Comparison_Exp>;
  confirmation_sent_at?: InputMaybe<Timestamp_Comparison_Exp>;
  confirmation_token?: InputMaybe<String_Comparison_Exp>;
  confirmed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  country_code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  encrypted_password?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  otp_secret_key?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  remember_created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  reset_password_sent_at?: InputMaybe<Timestamp_Comparison_Exp>;
  reset_password_token?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  unconfirmed_email?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint */
  IndexUsersOnConfirmationToken = 'index_users_on_confirmation_token',
  /** unique or primary key constraint */
  IndexUsersOnEmail = 'index_users_on_email',
  /** unique or primary key constraint */
  IndexUsersOnResetPasswordToken = 'index_users_on_reset_password_token',
  /** unique or primary key constraint */
  IndexUsersOnUsername = 'index_users_on_username',
  /** unique or primary key constraint */
  UsersPkey = 'users_pkey'
}

/** input type for incrementing numeric columns in table "users" */
export type Users_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  allow_password_change?: InputMaybe<Scalars['Boolean']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']>;
  confirmation_token?: InputMaybe<Scalars['String']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  encrypted_password?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  otp_secret_key?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_token?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  unconfirmed_email?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  confirmation_sent_at?: Maybe<Scalars['timestamp']>;
  confirmation_token?: Maybe<Scalars['String']>;
  confirmed_at?: Maybe<Scalars['timestamp']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  encrypted_password?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  otp_secret_key?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  remember_created_at?: Maybe<Scalars['timestamp']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']>;
  reset_password_token?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  unconfirmed_email?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  confirmation_sent_at?: Maybe<Scalars['timestamp']>;
  confirmation_token?: Maybe<Scalars['String']>;
  confirmed_at?: Maybe<Scalars['timestamp']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  encrypted_password?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  otp_secret_key?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  remember_created_at?: Maybe<Scalars['timestamp']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']>;
  reset_password_token?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  unconfirmed_email?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  username?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** on conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  allow_password_change?: InputMaybe<Order_By>;
  confirmation_sent_at?: InputMaybe<Order_By>;
  confirmation_token?: InputMaybe<Order_By>;
  confirmed_at?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  encrypted_password?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  otp_secret_key?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  remember_created_at?: InputMaybe<Order_By>;
  reset_password_sent_at?: InputMaybe<Order_By>;
  reset_password_token?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  unconfirmed_email?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  AllowPasswordChange = 'allow_password_change',
  /** column name */
  ConfirmationSentAt = 'confirmation_sent_at',
  /** column name */
  ConfirmationToken = 'confirmation_token',
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EncryptedPassword = 'encrypted_password',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Name = 'name',
  /** column name */
  OtpSecretKey = 'otp_secret_key',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  RememberCreatedAt = 'remember_created_at',
  /** column name */
  ResetPasswordSentAt = 'reset_password_sent_at',
  /** column name */
  ResetPasswordToken = 'reset_password_token',
  /** column name */
  Role = 'role',
  /** column name */
  UnconfirmedEmail = 'unconfirmed_email',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  allow_password_change?: InputMaybe<Scalars['Boolean']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']>;
  confirmation_token?: InputMaybe<Scalars['String']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  encrypted_password?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  otp_secret_key?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_token?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  unconfirmed_email?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  __typename?: 'users_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  __typename?: 'users_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  __typename?: 'users_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  __typename?: 'users_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  AllowPasswordChange = 'allow_password_change',
  /** column name */
  ConfirmationSentAt = 'confirmation_sent_at',
  /** column name */
  ConfirmationToken = 'confirmation_token',
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EncryptedPassword = 'encrypted_password',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Name = 'name',
  /** column name */
  OtpSecretKey = 'otp_secret_key',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  RememberCreatedAt = 'remember_created_at',
  /** column name */
  ResetPasswordSentAt = 'reset_password_sent_at',
  /** column name */
  ResetPasswordToken = 'reset_password_token',
  /** column name */
  Role = 'role',
  /** column name */
  UnconfirmedEmail = 'unconfirmed_email',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  __typename?: 'users_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  __typename?: 'users_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  __typename?: 'users_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type CreateAttachmentMutationVariables = Exact<{
  signedId: Scalars['String'];
  relatedId: Scalars['Int'];
  relatedType: Scalars['String'];
  attribute: Scalars['String'];
}>;


export type CreateAttachmentMutation = { __typename?: 'mutation_root', createAttachment?: { __typename?: 'CreateAttachmentOutput', attachment: { __typename?: 'Attachment', id: string, url: string, thumbnailUrl: string, createdAt: any } } | null };

export type CreateAttachmentsMutationVariables = Exact<{
  signedIds: Array<Scalars['String']> | Scalars['String'];
  relatedId: Scalars['Int'];
  relatedType: Scalars['String'];
  attribute: Scalars['String'];
}>;


export type CreateAttachmentsMutation = { __typename?: 'mutation_root', createAttachments?: { __typename?: 'CreateAttachmentsOutput', attachments: Array<{ __typename?: 'Attachment', id: string, url: string, createdAt: any, thumbnailUrl: string }> } | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'query_root', me?: { __typename?: 'MeOutput', id: number, username?: string | null, name?: string | null, email?: string | null, avatar?: any | null, currentPasswordRequired?: boolean | null, phoneNumber?: string | null } | null };

export type UpdateUserMutationVariables = Exact<{
  pk_columns: Users_Pk_Columns_Input;
  _set?: InputMaybe<Users_Set_Input>;
}>;


export type UpdateUserMutation = { __typename?: 'mutation_root', update_users_by_pk?: { __typename?: 'users', id: any, username?: string | null, name?: string | null, email?: string | null, phoneNumber?: string | null, avatar?: { __typename?: 'Attachment', id: string, url: string, thumbnailUrl: string } | null } | null };

export type UpsertDeviceMutationVariables = Exact<{
  device: Devices_Insert_Input;
}>;


export type UpsertDeviceMutation = { __typename?: 'mutation_root', insert_devices_one?: { __typename?: 'devices', id: any } | null };

export type User_FragmentFragment = { __typename?: 'users', id: any, username?: string | null, name?: string | null, email?: string | null, phoneNumber?: string | null, avatar?: { __typename?: 'Attachment', id: string, url: string, thumbnailUrl: string } | null };

export const User_FragmentFragmentDoc = gql`
    fragment user_fragment on users {
  id
  username
  name
  email
  phoneNumber: phone_number
  avatar {
    id
    url
    thumbnailUrl
  }
}
    `;
export const CreateAttachmentDocument = gql`
    mutation CreateAttachment($signedId: String!, $relatedId: Int!, $relatedType: String!, $attribute: String!) {
  createAttachment(
    input: {relatedId: $relatedId, relatedType: $relatedType, attribute: $attribute, signedId: $signedId}
  ) {
    attachment {
      id
      url
      thumbnailUrl
      createdAt
    }
  }
}
    `;
export type CreateAttachmentMutationFn = Apollo.MutationFunction<CreateAttachmentMutation, CreateAttachmentMutationVariables>;
export type CreateAttachmentMutationResult = Apollo.MutationResult<CreateAttachmentMutation>;
export type CreateAttachmentMutationOptions = Apollo.BaseMutationOptions<CreateAttachmentMutation, CreateAttachmentMutationVariables>;
export const CreateAttachmentsDocument = gql`
    mutation CreateAttachments($signedIds: [String!]!, $relatedId: Int!, $relatedType: String!, $attribute: String!) {
  createAttachments(
    input: {relatedId: $relatedId, relatedType: $relatedType, attribute: $attribute, signedIds: $signedIds}
  ) {
    attachments {
      id
      url
      createdAt
      thumbnailUrl
    }
  }
}
    `;
export type CreateAttachmentsMutationFn = Apollo.MutationFunction<CreateAttachmentsMutation, CreateAttachmentsMutationVariables>;
export type CreateAttachmentsMutationResult = Apollo.MutationResult<CreateAttachmentsMutation>;
export type CreateAttachmentsMutationOptions = Apollo.BaseMutationOptions<CreateAttachmentsMutation, CreateAttachmentsMutationVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    username
    name
    email
    currentPasswordRequired: current_password_required
    phoneNumber: phone_number
    avatar
  }
}
    `;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($pk_columns: users_pk_columns_input!, $_set: users_set_input) {
  update_users_by_pk(pk_columns: $pk_columns, _set: $_set) {
    ...user_fragment
  }
}
    ${User_FragmentFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpsertDeviceDocument = gql`
    mutation UpsertDevice($device: devices_insert_input!) {
  insert_devices_one(
    object: $device
    on_conflict: {constraint: devices_user_exponent_push_token_constraint, update_columns: [updated_at, deleted_at]}
  ) {
    id
  }
}
    `;
export type UpsertDeviceMutationFn = Apollo.MutationFunction<UpsertDeviceMutation, UpsertDeviceMutationVariables>;
export type UpsertDeviceMutationResult = Apollo.MutationResult<UpsertDeviceMutation>;
export type UpsertDeviceMutationOptions = Apollo.BaseMutationOptions<UpsertDeviceMutation, UpsertDeviceMutationVariables>;